<template>
    <el-dialog
            title="选择档号规则"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="120px" @submit.native.prevent>
            <el-form-item label="档号规则：" prop="oldSequenceNumber">
                <el-select v-model="inputForm.oldSequenceNumber" placeholder="请选择" style="width: 100%">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.templateName"
                            :value="item.id">
                    </el-option>
                </el-select>
<!--                <el-input v-model="inputForm.templateName" placeholder="请输入模板名"></el-input>-->
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                options:[],
                inputForm: {
                    oldSequenceNumber: '',
                },
                dataRule: {
                    oldSequenceNumber: [
                        {required: true, message: '档号规则不能为空', trigger: 'blur'}
                    ]
                },
                method:"",
                list:[],
                uploadTarget:"",
                directoryName:"",
                dataListSelections:[],
                log:{
                    operModular:"3",//模块
                    operType:"1",//类型
                    operTerm:"",//内容
                    operSystem:"1",//结果
                },
            }
        },
        methods: {
            init (method,list,dataListSelections,uploadTarget,directoryName) {
                this.visible = true
                this.loading = true
                this.$axios(this.api.infor.queryAllByTemplateType, {}, "get").then(data => {
                    this.loading = false
                    if (data && data.status) {
                        this.options=data.data
                    }
                })
                this.method=method
                this.list=list
                this.uploadTarget=uploadTarget;
                this.dataListSelections=dataListSelections
                this.directoryName=directoryName
                // console.log("方法：",this.method);
                // console.log("数据：",this.list);
                // console.log("目录id：",this.uploadTarget);
                // console.log("目录名：",this.directoryName);
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },
            //一键归档，新增日志
            logSave3(){
                // this.log.operTerm = this.directoryName
                // this.$axios(this.api.common.batchInsertLogin,this.log,'post').then(data => {
                    this.visible = false
                    this.$emit('refreshDataList')
                // })
            },
            logSave(dataListSelections){
                // let that = this;
                // let batchList = []
                // dataListSelections.forEach(function (v) {
                //     // console.log(v.fileName);
                //     let log = JSON.parse(JSON.stringify(that.log))
                //     log.operTerm = v.fileName
                //     // console.log(log.operTerm);
                //     batchList.push(log)
                // })
                // this.$axios(this.api.common.batchInsertLogin,batchList,'post').then(data => {
                    this.visible = false
                    this.$emit('refreshDataList')
                // })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.method == 'archive'){
                            this.log.operType = '12'
                            this.$axios(this.api.common.culturalRelicsArchive, {
                                'ids':this.list,
                                "oldSequenceNumber":this.inputForm.oldSequenceNumber
                            }, 'put').then(data => {
                                if (data && data.status) {
                                    this.log.operSystem = '0'
                                    this.$message.success("已归档")
                                    // this.getFileList();
                                }else{
                                    this.log.operSystem = '1'
                                    this.$message.error(data.msg)
                                }
                                this.logSave(this.dataListSelections);
                            })
                        }else{
                            this.log.operType = '13'
                            this.$axios(this.api.common.culturalRelicsKeyArchive, {
                                'exhibitionId':this.uploadTarget,
                                "oldSequenceNumber":this.inputForm.oldSequenceNumber
                            }, 'put').then(data => {
                                if (data && data.status) {
                                    this.log.operSystem = '0'
                                    this.$message.success("已归档")
                                    // this.getFileList();
                                }else{
                                    this.$message.error(data.msg)
                                    this.log.operSystem = '1'
                                }
                                this.logSave3()
                            })
                        }
                    }
                })
            }
        }
    }
</script>
